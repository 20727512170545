@tailwind base;
html {
  @apply bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-300 antialiased text-base leading-relaxed;
}
h1 {
  @apply font-extrabold text-3xl sm:text-4xl;
}
h2 {
  @apply font-extrabold text-2xl sm:text-3xl;
}
h3 {
  @apply font-bold text-xl sm:text-2xl;
}
h4 {
  @apply font-bold text-lg sm:text-xl;
}
h5 {
  @apply font-semibold text-base sm:text-lg;
}
h6 {
  @apply font-semibold text-sm sm:text-base;
}

hr {
  @apply w-full border-0 mx-0 my-8 bg-gray-200 dark:bg-gray-700 bg-opacity-50;
  height: 1px;
}

a {
  @apply text-blue-500 font-semibold hover:text-blue-600 active:text-blue-700;
}

.top-links a {
  @apply font-normal text-gray-400 hover:text-gray-100;
}
.top-links a.active {
  @apply text-gray-50 hover:text-gray-100;
}

label {
  @apply opacity-70 font-semibold whitespace-nowrap flex-shrink-0;
}

select,
input,
textarea,
button {
  @apply outline-none;
}

table {
  @apply border-collapse w-full;
}

table tr th {
  @apply font-normal uppercase opacity-60;
}
table tr td,
table tr th {
  @apply p-2 text-left;
}
table.with-px-2 tr th,
table.with-px-2 tr td {
  @apply px-2;
}

input {
  @apply outline-none;
}

a svg,
a svg > path {
  fill: currentColor;
}

*:disabled {
  @apply opacity-50 cursor-not-allowed;
}

@tailwind components;

.card {
  @apply bg-white dark:bg-gray-800 dark:border dark:border-gray-700 dark:border-opacity-50 rounded-3xl px-8 py-6 shadow-sm;
}
.card-in-modal {
  @apply bg-white dark:bg-gray-700 dark:bg-opacity-30 dark:border dark:border-gray-700 dark:border-opacity-50 rounded-3xl px-8 py-6 shadow-sm;
}

.bounce {
  transition: all 0.3s cubic-bezier(0.1, 1.11, 0.41, 2.05);
}

/* Spinner */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 3px solid #fff;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@tailwind utilities;

.delayHide {
  -moz-animation: cssDelayHide 0s ease-in 5s forwards;
  -webkit-animation: cssDelayHide 0s ease-in 5s forwards;
  -o-animation: cssDelayHide 0s ease-in 5s forwards;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes cssDelayHide {
  to {
      width:0;
      height:0;
      overflow:hidden;
  }
}
@-webkit-keyframes cssDelayHide {
  to {
      width:0;
      height:0;
      visibility:hidden;
  }
}

.animate-ease-1 {
  transition: all 0.1s ease;
}
.animate-ease-2 {
  transition: all 0.2s ease;
}
.animate-ease-3 {
  transition: all 0.3s ease;
}
.animate-ease-5 {
  transition: all 0.5s ease;
}
.animate-cuber-5 {
  transition: all 0.4s cubic-bezier(0.3, 0.8, 0.4, 1);
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  @apply rounded-2xl px-4 py-3 min-h-0 backdrop-blur;
}
.Toastify__toast-body {
  @apply m-0;
}

/** Used to position the icon **/
.Toastify__toast-icon {
}

/** handle the notificaiton color and the text color based on the theme **/
.Toastify__toast-theme--dark {
}
.Toastify__toast-theme--light {
}
.Toastify__toast-theme--colored.Toastify__toast--default {
}
.Toastify__toast-theme--colored.Toastify__toast--info {
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  @apply bg-green-500 dark:bg-green-700 bg-opacity-80 dark:bg-opacity-70;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  @apply bg-red-500 dark:bg-red-700 bg-opacity-80 dark:bg-opacity-70;
}

.Toastify__progress-bar {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
}
/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}
